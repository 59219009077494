import './App.css';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/monokai.css';


import React, { useState, useEffect } from 'react';
import {Button, Divider, Layout, Menu, Space, Table, Tag} from "antd";
import {DeleteOutlined, DownloadOutlined} from "@ant-design/icons";
import CodeMirror from '@uiw/react-codemirror';

const { Content, Footer } = Layout;

const sampleCode = `
<!--@layout(/layout/basic/layout.html)-->
<div id="titleArea">
    <h2 class="title">배송조회</h2>
    <span module="Layout_MobileAction">
        <a href="#none" onclick="{$go_back}" class="btnBack">뒤로가기</a>
    </span>
</div>

<script type="application/delivery-tracker-template-v1" order_id="order_id">
<div style="border: 1px solid #000; margin: 3px;">
  {{#unless tracks}}
    <div>배송 정보를 찾을 수 없습니다.</div>
  {{/unless}}
  
  {{#each tracks}}
    {{#if message}}
        <div>{{message}}</div>
    {{/if}}
    <div>
        <div>
          <div style="display: inline-block;">택배사</div>
          <div style="display: inline-block;">{{carrier.name}}</div>
        </div>
        <div>
          <div style="display: inline-block;">발송인</div>
          <div  style="display: inline-block;">
            <span>{{from.name}}</span>
            <span>{{date from.time "YY-MM-DD HH:mm"}}</span>
          </div>
        </div>
        <div>
          <div style="display: inline-block;">수신인</div>
          <div  style="display: inline-block;">
            <span>{{to.name}}</span>
            <span>{{date to.time "YY-MM-DD HH:mm"}}</span>
          </div>
        </div>
        <div>
          <div style="width: 200px; display: inline-block;">상태</div>
          <div  style="width: 200px; display: inline-block;">{{state.text}}</div>
        </div>
        <div>
            <div>
                  <div style="width: 200px; display: inline-block;">시간</div>
                  <div style="width: 100px; display: inline-block;">위치</div>
                  <div style="width: 100px; display: inline-block;">상태</div>
                  <div style="width: 300px; display: inline-block;">설명</div>
            </div>
            {{#each progresses}}
              <div>
                    <div style="width: 200px; display: inline-block;">{{date time "YY-MM-DD HH:mm"}}</div>
                    <div style="width: 100px; display: inline-block;">{{location.name}}</div>
                    <div style="width: 100px; display: inline-block;">{{status.text}}</div>
                    <div style="width: 300px; display: inline-block;">{{description}}</div>
              </div>
            {{/each}}
        </div>
    </div>
  {{/each}}
</div>

</script>`;

function App() {
    const [shops, setShops] = useState([]);
    const [actionState, setActionState] = useState({});

    function updateShopList() {
      const access_token = new URL(window.location.href).searchParams.get("access_token");
      fetch(
        "https://apis.cafe24.cloud.tracker.delivery/admin/shops",
        {"headers": {"Authorization": "Bearer " + access_token}}
      ).then(response => {
        if(response.status === 401) {
          const mall_id = new URL(window.location.href).searchParams.get("mall_id");
          window.location.href = `https://apis.cafe24.cloud.tracker.delivery/oauth?mall_id=${mall_id}`;
        }
        return response.json()
      }).then(body => setShops(body.data));
    }

    useEffect(() => {
      updateShopList();
    }, []);

    function installSDK(shop) {
        let newActionState = {...actionState};
        newActionState[`install/${shop.shop_no}`] = true;
        setActionState(newActionState);

        const access_token = new URL(window.location.href).searchParams.get("access_token");

        fetch(
          "https://apis.cafe24.cloud.tracker.delivery/admin/sdk",
          {
            method: 'POST',
            headers: {'Authorization': `Bearer ${access_token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({
              'shop_no': shop.shop_no
            })
          }
        ).then(response => {
          let newActionState = {...actionState};
          delete newActionState[`install/${shop.shop_no}`];
          setActionState(newActionState);
          updateShopList();
        });
    }

    function uninstallSDK(shop) {
      let newActionState = {...actionState};
      newActionState[`install/${shop.shop_no}`] = true;
      setActionState(newActionState);

      const access_token = new URL(window.location.href).searchParams.get("access_token");

      fetch(
        "https://apis.cafe24.cloud.tracker.delivery/admin/sdk",
        {
          method: 'DELETE',
          headers: {'Authorization': `Bearer ${access_token}`, 'Content-Type': 'application/json'},
          body: JSON.stringify({
            'shop_no': shop.shop_no
          })
        }
      ).then(response => {
        let newActionState = {...actionState};
        delete newActionState[`uninstall/${shop.shop_no}`];
        setActionState(newActionState);
        updateShopList();
      });
    }

  const columns = [
    {
      title: 'Shop No',
      dataIndex: 'shop_no',
      key: 'shop_no',
    },
    {
      title: 'Shop Name',
      dataIndex: 'shop_name',
      key: 'shop_name',
      render: (text, record) => (
        <Space size="middle">
          <span>{text}</span>
          {record.sdk_installed ? <Tag color="green"><DownloadOutlined /> Installed</Tag> : <div/> }
        </Space>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {
            !record.sdk_installed ?
            <Button type="primary" loading={actionState[`install/${record.shop_no}`]} shape="round" icon={<DownloadOutlined />} size="small" onClick={e => installSDK(record)}>Install</Button> :
            <Button type="danger" loading={actionState[`uninstall/${record.shop_no}`]} shape="round" icon={<DeleteOutlined />} size="small" onClick={e => uninstallSDK(record)}>Uninstall</Button>
          }
        </Space>
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content className="site-layout-background" style={{ margin: '24px auto', width: '600px'}}>
        <h1 style={{marginTop: '24px', textAlign: 'center'}}>Delivery Tracker x Cafe24</h1>
        <Divider />
        <Table dataSource={shops} columns={columns} pagination={false} rowKey="shop_no"/>
        <Divider />

        <Content style={{ margin: '12px 24px'}}>
          <h3>Guide</h3>
          <h4>1. 설치</h4>
          <p>본 페이지 상단에 존재하는 쇼핑몰 목록에서 설치를 원하는 쇼핑몰 항목의 Install 버튼을 클릭하여 설치</p>
          <h4>2. 배송 조회 용 페이지 생성 (카페24 스마트디자인)</h4>
          <p>카페24 스마트디자인을 통해 배송 조회용으로 사용할 페이지를 생성하고 아래 예제 코드를 참고하여 배송 조회 페이지 개발</p>
          <p>이때 사용할 수 있는 변수는 <a target="_blank" href="https://tracker.delivery/guide">Delivery Tracker Guide</a>의 배송 조회 API를 따르며 템플릿 문법은 Handlebars를 따릅니다.</p>
          <CodeMirror
            value={sampleCode}
            options={{
              theme: 'monokai',
              keyMap: 'sublime',
              mode: 'jsx',
            }}
          />
          <h4>3. 배송 조회 용 페이지를 다른 페이지와 연결</h4>
          <p>주문 목록 페이지 등 배송 조회 페이지로 이동을 원하는 페이지에 다음 예시와 같은 형태로 위에서 만든 배송조회 페이지로 order_id를 전달합니다.</p>
          <CodeMirror
            value={`<a href="shipping.html?order_id={$order_id}" className="line btnNormal mini">배송조회</a>`}
            options={{
              theme: 'monokai',
              keyMap: 'sublime',
              mode: 'jsx',
            }}
          />

        </Content>

      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <a target="_blank" href="https://github.com/shlee322/delivery-tracker">https://github.com/shlee322/delivery-tracker</a>
      </Footer>
    </Layout>
  );
}

export default App;
